.main-btn {
    width: fit-content;
}

.main-btn button {
    outline: none;
    border: none;
    font-size: 32px;
    background: none;
    cursor: pointer;
    color: black;
    width: 100%;
    height: 70px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    font-family: "Kodchasan", sans-serif;
    color: black;
    background-color: #FFF9C3;
    box-shadow: 0 10px 0px 0 #86804D;
}

.main-btn button:hover {
    background-color: #fff9c3db;
}

.main-btn.disabled button {
    cursor:not-allowed;
}