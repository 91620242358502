.footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 120px;
    padding: 18px 0;
}

.footer img {
    margin-right: 24px;
    border-radius: 50%;
}