.main {
    margin: 0 auto;
    width: 1280px;
    margin-bottom: 90px;
}

@media (max-width: 1280px) {
    .main {
        width: 100%;
        margin: 0 auto;
    }
}

